import { IncFaIconName, ISaxIconProps } from "@inception/ui";
import { DataType, EntityPropertyValue } from "../../../../core";
import { MappingHealthStatus, SourceSummary, UserServiceFieldDef } from "../../event-mapping";
import { BizDataQuery, BizEntityTypeList, ResponseInfo, ResultEntityList } from "../../explore";
import { DimensionV2 } from "../../onboarding";
import { OpMetadata } from "../../operationalise";
import { EntitySearchResultEntry } from "../../types";
import { UseCaseSummary } from "./use-case";

interface CommonProperties {
  id: string;
  defaultName: string;
  overrideName: string;
  description: string;
  icon: string;
  aliases: string[];
}

export interface VerticalBicycleQueryV2 extends CommonProperties {
  eventType: string;
  entityType: string;
  cohort: string;

  field: DimensionV2;
  aggregator: string;

  filter: FilterV2;
  slices: DimensionV2[];
}

export interface FilterV2 {
  field: DimensionV2;
  op: string;
  value: EntityPropertyValue;
}

export interface CustomWidgetV2 extends CommonProperties {
  dataSource: VerticalBicycleQueryV2;
}

export interface UseCaseV2 extends CommonProperties {
  widgets: CustomWidgetV2[];
}

export interface SuggestedUsecaseV2 {
  summary: UseCaseSummary;
  rawUseCase: UseCaseV2;
}

export interface WidgetDescriptionList {
  widgetDescriptions: WidgetDescription[];
}

export interface InsightDescriptionList {
  insightDescriptions: InsightDescription[];
}

export interface WidgetDescription {
  name: string;
  description: string;
}

export interface InsightDescription extends Omit<OpMetadata, "icon"> {
  name: string;
  description: string;
  icon: string;

  metric: string;
  trigger: string;
  causes: string[];

  automation: string;
  importance: string;

  involvedEvents?: string[];
  involvedDimensions?: string[];
  associatedKpiId?: string;
  associatedKpiName?: string;
}

export interface UsecaseDefinitionSummary {
  name: string;
  icon: string;
  description: string;

  widgetDescriptionList: WidgetDescriptionList;
  insightDescriptionList: InsightDescriptionList;

  subVertical?: string;
}

export interface KPISummary {
  kpi: BizDataQuery;
  name: string;
  description: string;
  icon: IncFaIconName | ISaxIconProps["iconName"];
  kpiType?: string; // for the pill

  isSpikePositive?: boolean;
  subType?: DataType;
}

export enum IncidentSummaryCauseType {
  cause_type_unset = "cause_type_unset",
  biz_event = "biz_event",
  change_event = "change_event",
  technical = "technical"
}

export interface MetadataSource {
  id: string;
  name: string;
  sourceType: string;
  entityTypeIds: string[];
  heathStatus: MappingHealthStatus;
}

export type RawFieldDef = {
  entityTypeId: string;
  id: string;
  mapsToEntity: boolean;
  name: string;
  status: MappingHealthStatus;
  userServiceField: UserServiceFieldDef;
};

interface UserServiceDefinitionResponse {
  id: string;
  name: string;
  sourceSummary: SourceSummary[];
  fieldResponse: Record<string, RawFieldDef>;
  status: MappingHealthStatus;
}

export interface EventSourceStatus {
  eventSource: SourceSummary;
  eventTypes: UserServiceDefinitionResponse[];
}

export interface WidgetDataFlowResponse {
  eventSource: EventSourceStatus[];
  metaDataSource: MetadataSource[];
  eventTypeIds: string[];
  entityTypeIds: string[];
}

export interface UseCaseUnderlyingInfo {
  entityTypeIds: BizEntityTypeList;
  eventIds: ResultEntityList;
  eventIdToEntityType: Record<string, BizEntityTypeList>;
  entityTypeToEventId: Record<string, ResultEntityList>;
}

export interface VerticalIdentifier {
  vertical: string;
  subVertical: string;
  companyName: string;
}

export interface QueryField {
  eventField: EntitySearchResultEntry["entity"];
  entityField: string;
}

export interface UseCaseSuggestionsResponse {
  useCaseRecommendations: RecommendedUseCase[];
  responseInfo: ResponseInfo;
  statusCode: number;
}

export interface RecommendedUseCase {
  name: string;
  description: string;
  icon: IncFaIconName | ISaxIconProps["iconName"];
  verticalIdentifier: VerticalIdentifier;
  involvedEventsList: string[];
  involvedEventDimensions: QueryField[];

  entityLookupData?: Record<string, string>;
  category: string;
}
